<template>
   <v-app>
      <v-main class="ma-0">
         <div class="login-container d-flex align-center">
            <v-card
               class="glass mx-auto pa-5 text-center"
               width="500"
               max-width="500"
               justify="space-around"
                transition="slide-y-transition"
               timeout="3000"
            >
               <v-form ref="form" @submit.prevent="sendEmail">
                  <img class="mblogo align-center" src="@/assets/logo_wide.png" />
                  <h4 class="text-center"><br></h4>
                  <h4 class="text-center">Enter email address in order to reset your password</h4>
                  <h6 class="text-center"><br></h6>
                  <v-spacer></v-spacer>
                  <v-text-field
                     v-model="email"
                     placeholder="Email"
                     append-icon="mdi-lock"
                     type="email"
                     hide-details="auto"
                     solo
                     light
                  ></v-text-field>

                  <v-card-actions class="pa-0 pt-5 justify-end">
                     <router-link to="/login" class="forgotPassowrd">
                        back to login
                     </router-link>
                     <v-spacer></v-spacer>
                     <v-btn
                        class="align-center"
                        x-large
                        :loading="loading"
                        color="primary"
                        type="submit">
                        Send
                        
                     </v-btn>
                  </v-card-actions>
               </v-form>
            </v-card>
            
         </div>
         <div class="credit">
            <div>
               Photo by <a :href="photoInfoUrl" target="_blank">{{photoInfoName}} </a> on <a href="https://unsplash.com/?utm_source=metabim&utm_medium=referral"> Unsplash</a>
            </div>
         </div>
         <v-snackbar
            v-model="snackbar"
            :color="messageType"
            elevation="10"
            transition="slide-y-transition"
            timeout="3000"
            @input="resetSnackbar"
         >
            {{ message }}

         </v-snackbar>

      </v-main>
   </v-app>
</template>

<script>
/* eslint-disable */
import { createApi } from 'unsplash-js';
import nodeFetch from 'node-fetch';
import { auth } from "@/main";
import Util from '@/utils';

export default {
  data() {
    return {
       unsplash: createApi({
            accessKey: 'ugIYTA1RzIKC5AKJL0uNUd-AMAlEFikQP4if-P4I924',
            fetch: nodeFetch,
         }),
         photo: '',
         photoInfoUrl: '',
         photoInfoName: '',
      snackbar: false,
      loading: false,
      token: "",
      email: "",
      messageType: ""
    }
  },
  computed: {
    message() {
      let msg = this.$store.state.app.message;
      if (msg) {
        this.snackbar = true;
      }
      return this.$store.state.app.message;
    },
  },  
  mounted() {
   this.unsplash.photos.getRandom({
         query: 'industrial factory refinery',
         orientation: 'landscape',
         collections: ['8k1VK2dtJ1Y', '6u5oWZxOVG8'],
      }).then((result) => {
         if (result.errors) {
            console.log('error', result.errors[0]);
         } else {
            this.photo = result.response;
            this.photoInfoUrl = this.photo.user.links.html+'?utm_source=metabim&utm_medium=referral';
            this.photoInfoName = this.photo.user.name;
            document.querySelector('#app > div > main').loading = 'lazy';
            document.querySelector('#app > div > main').style.backgroundImage = `url(${this.photo.urls.regular})`;
            document.querySelector('#app > div > main').style.backgroundSize = 'cover';
            document.querySelector('#app > div > main').style.backgroundPosition = 'center';
         }
      });
    
  },
  methods: {
    resetSnackbar() {
      this.$store.dispatch("alertUser", "");
    },
    sendEmail(){
      //recover
      //this.$mixpanel.track('Password Reset', response);
      Util.sendEvent('Password Reset', 'Send Email');
      auth.recover(this.email);
    }
  },
}
</script>

<style rel="stylesheet/scss" lang="scss">
 .mblogo {
    padding-top:10px;
    width: 250px;
  }
  .login-container {
    width: 100%;
    height: 100%;
  }


a {
  color: #fff;
}
a:visited {
  color: #fff;
}  
.forgotPassowrd {
  float: right;
  font-size: 85%;
}
.glass
{
   background-image: radial-gradient(circle at 50% 100%, #4accff66 0%, #00224433 50%), radial-gradient(circle at 50% 20%, rgba(0,34,68,0.9) 30%, rgba(0,34,68,0.6) 80%, rgba(0,34,68,0.4) 150%)  ;
   backdrop-filter: blur(10px) ;
   -webkit-backdrop-filter: blur(10px);
   border-radius: 15px !important;
   /*shadow*/
   box-shadow: 0 8px 32px 0 rgba(0,0,0, 0.8) !important;
   border: 1px solid rgba(0, 34, 68, 0.3) !important;
}
.credit {
  position: absolute;
  background-image: linear-gradient(180deg, rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.8) 100%);
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0.5rem 0.5rem;
  margin: 0px;
  height: 100px;
  font-size: 12px;
  color: #ffffff99;
  border-radius: 5px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: flex-end;
  align-items: flex-end;

  a {
    color: #ffffff99 !important;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

h4{
   color: #fff;
}
</style>
